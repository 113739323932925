import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Select,
  Switch,
  Space,
  Button,
  DatePicker,
  Table,
  Statistic,
} from "antd";
import Barcode from "react-barcode";
import { getProductCategorySelect } from "../../services/category.service";
import { getProductUnitSelect } from "../../services/unit.service";
import Swal from 'sweetalert2'
import { createProduct, getProductById, getProductStockDetailPaging, updateProduct } from "../../services/product.service";
import { handleKeyDecimalPress, handleKeyPress } from "../../services/helper.service";
import { getProductGroupSelect } from "../../services/group.service";
import { getProductTypeSelect } from "../../services/type.service";
import { getProductBrandSelect } from "../../services/brand.service";
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 14 },
  },
};

const StockManage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [productId, setProductId] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const menuCode = ""
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [sortField, setSortField] = useState()
  const [sortOrder, setSortOrder] = useState()
  const [search, setSearch] = useState()
  const [productTotal, setProductTotal] = useState(0)
  const [productRemainTotal, setProductRemainTotal] = useState(0)

  const onChange = (pagination, filters, sorter, extra) => {
    setCurrentPage(pagination?.current)
    setSortField(sorter?.field)
    setSortOrder(sorter?.order)
    setPerPage(pagination?.pageSize);
  };
  const columns = [
    {
      title: "วันหมดอายุ",
      dataIndex: "expriedDate",
      key: "expriedDate",
      sorter: true,
    },
    {
      title: "เลขที่ใบรับเข้า",
      dataIndex: "receiveNo",
      key: "receiveNo",
      sorter: true,
    },
    {
      title: "เลขที่เอกสารอ้างอิง",
      dataIndex: "receiveRef",
      key: "receiveRef",
      sorter: true,
    },
    {
      title: "คลังสินค้า",
      dataIndex: "warehouseName",
      key: "warehouseName",
      sorter: true,
    },
    {
      title: "จำนวนทั้งหมด",
      dataIndex: "total",
      key: "total",
      sorter: true,
      render: (value) => new Intl.NumberFormat('th-TH').format(value),
    },
    {
      title: "จำนวนคงเหลือ",
      dataIndex: "remainTotal",
      key: "remainTotal",
      sorter: true,
      render: (value) => new Intl.NumberFormat('th-TH').format(value),
    },
    {
      title: "ราคาทุน",
      dataIndex: "costPrice",
      key: "costPrice",
      sorter: true,
      render: (value) => new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
    },
    {
      title: "ราคาส่งร้านค้า",
      dataIndex: "costShop",
      key: "costShop",
      sorter: true,
      render: (value) => new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
    },
    {
      title: "ราคาส่งเกษตรกร",
      dataIndex: "farmerPrice",
      key: "farmerPrice",
      sorter: true,
      render: (value) => new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
    },
    {
      title: "ราคาปลีก",
      dataIndex: "retailPrice",
      key: "retailPrice",
      sorter: true,
      render: (value) => new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
    },
  ];
  const loadDropdownList = async () => {
    const { data: category } = await getProductCategorySelect()
    if (category) {
      setCategoryList(category)
    }
    const { data: unit } = await getProductUnitSelect()
    if (unit) {
      setUnitList(unit)
    }
    const { data: group } = await getProductGroupSelect()
    if (group) {
      setGroupList(group)
    }
    const { data: type } = await getProductTypeSelect()
    if (type) {
      setTypeList(type)
    }
    // const { data: supplier } = await getSupplierSelect()
    // if (supplier) {
    //   setSupplierList(supplier)
    // }
    const { data: brand } = await getProductBrandSelect()
    if (brand) {
      setBrandList(brand)
    }
  }
  const loadData = async (param) => {
    const { data } = await getProductById(param)
    if (data) {
      setIsActive(data.isActive)
      form.setFieldsValue({
        productId: data?.productId,
        productCode: data?.productCode,
        productName: data?.productName,
        detail: data?.detail,
        categoryId: data?.categoryId,
        unitId: data?.unitId,
        typeId: data?.typeId,
        // supplierId: data?.supplierId,
        productCommonName: data?.productCommonName,
        productSubstances: data?.productSubstances,
        productRegisterCode: data?.productRegisterCode,
        productKeyIngredinents: data?.productKeyIngredinents,
        productUsage: data?.productUsage,
        productHowto: data?.productHowto,
        productCautions: data?.productCautions,
        productPackaging: data?.productPackaging,
        productWeight: data?.productWeight,
        productFormula: data?.productFormula,
        productSecElements: data?.productSecElements,
        brandId: data?.brandId,
        productSize: data?.productSize,
        productGroupId: data?.productGroupId,
      });
    }
  }
  const fetchData = async (param) => {
    let response = await getProductStockDetailPaging({
      currentPage: param?.currentPage ?? 1,
      perPage: param?.perPage ?? 10,
      sortColumn: param?.sortColumn ?? '',
      sortDirection: param?.sortDirection ?? '',
      menuCode: menuCode ?? '',
      search: param?.isClear ? '' : search ?? '',
      productId: param?.productId
    })
    if (response?.data) {
      setData(response?.data?.data)
      setTotalRows(response?.data?.total)
      setProductTotal(response?.data.productTotal)
      setProductRemainTotal(response?.data.productRemainTotal)
    }
  }
  useEffect(() => {
    loadDropdownList()
    if (location?.state?.productId) {
      setProductId(location?.state?.productId)
      loadData(location?.state?.productId)
    } else {
      setIsActive(true)
    }
  }, []);
  useEffect(() => {
    fetchData({
      currentPage: currentPage,
      sortColumn: sortField ?? '',
      sortDirection: sortOrder ?? '',
      perPage: perPage,
      search: search ?? '',
      productId: location?.state?.productId
    })
  }, [currentPage, sortField, sortOrder, perPage, search])
  const rowClassName = (record) => {
    return record.remainTotal === 0 ? 'red-row' : '';
  };
  return (
    <>
      <Card title="ข้อมูลสินค้า" bordered={false}>
        <Form form={form} {...formItemLayout} disabled>
          <Row gutter={24}>
            <Col span={12}>
              <Card bordered={false}>
                <Statistic title="จำนวนสินค้าทั้งหมด" value={productTotal} valueStyle={{ color: '#3f8600' }} />
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered={false}>
                <Statistic title="จำนวนสินค้าคงเหลือ" value={productRemainTotal} valueStyle={{ color: '#cf1322' }} />
              </Card>
            </Col>
          </Row>
          <Row gutter={24} className="row-padding-10">
            <Col xs={24} lg={12}>
              <Form.Item
                label={`กลุ่มสินค้า`}
                name="productGroupId"
                rules={[{ required: true, message: "กรุณาเลือกกลุ่มสินค้า" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={groupList}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`รหัสสินค้า`}
                name="productCode"
              // rules={[{ required: true, message: "กรุณากรอกรหัสสินค้า" }]}
              >
                <Input
                  maxLength={20}
                  readOnly className="read-only"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชื่อสินค้า`}
                name="productName"
                rules={[{ required: true, message: "กรุณากรอกชื่อสินค้า" }]}
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชื่อสามัญ`}
                name="productCommonName"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ประเภทสินค้า`}
                name="categoryId"
                rules={[{ required: true, message: "กรุณาเลือกประเภทสินค้า" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={categoryList}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชนิด`}
                name="typeId"
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={typeList}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label={`ปริมาณ`} name="productWeight" rules={[{ required: true, message: "กรุณากรอกปริมาณ" }]}>
                <Input onKeyPress={handleKeyDecimalPress} placeholder="0.00" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`หน่วยสินค้า`}
                name="unitId"
                rules={[{ required: true, message: "กรุณาเลือกหน่วยสินค้า" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={unitList}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`สารสำคัญ`}
                name="productKeyIngredinents"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`กลุ่มสาร`}
                name="productSubstances"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ธาตุรอง`}
                name="productSecElements"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`สูตร`}
                name="productFormula"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`วิธีใช้`}
                name="productHowto"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ข้อควรระวัง`}
                name="productCautions"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`บรรจุต่อลัง/ชิ้น`}
                name="productPackaging"
              >
                <Input onKeyPress={handleKeyPress} placeholder="0" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ขนาด`}
                name="productSize"
              >
                <Input maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ทะเบียน`}
                name="productRegisterCode"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ยี่ห้อ`}
                name="brandId"
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={brandList}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ประโยชน์`}
                name="productUsage"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`ใช้งาน`}>
                <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
              </Form.Item>
            </Col>
            {/* <Col xs={24} lg={12}>
              <Form.Item
                label={`ผู้จัดซื้อ`}
                name="supplierId"
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={supplierList}
                />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label={`รายละเอียดอื่นๆ`} name="detail">
                <TextArea
                  rows={4}
                />
              </Form.Item>
            </Col>

          </Row>
          {/* <Row gutter={24}> */}
          {/* <Col xs={24} lg={12}>
              {productCode ? (
                <Form.Item label={`Barcode`}>
                  <Barcode value={productCode} height={50} fontSize={10}
                    bgColor="#fff" style={{ margin: "10px", padding: 0 }}
                    renderer="img"
                  />
                </Form.Item>
              ) : (
                <></>
              )}
            </Col> */}
          {/* </Row> */}
        </Form>
        <Row className="row-padding-10">
          <Col xs={24} lg={16} className="gutter-row" span={24}></Col>
          <Col xs={24} lg={8}>
            <Form.Item label={`ค้นหา`}>
              <Input value={search} placeholder="เลขที่ใบรับเข้า , เลขที่เอกสารอ้างอิง , คลังสินค้า" onChange={(e) => setSearch(e.target.value)} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="row">
          <Col className="gutter-row" span={24}>
            <Table
              dataSource={data}
              columns={columns}
              scroll={{ x: 500 }}
              rowClassName={rowClassName}
              onChange={onChange}
              pagination={{
                current: currentPage,
                pageSize: perPage,
                total: totalRows,
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} className="mt-3">
          <Col span={24}>
            <div style={{ textAlign: "center" }}>
              <Space size="middle">
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  กลับ
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default StockManage;
