import Dashboard from "./pages/Dashboard";
import CompanyManage from "./pages/company/companyManage";
import CustomerList from "./pages/customer/CustomerList";
import CustomerManage from "./pages/customer/CustomerManage";
import PaymentTypeList from "./pages/payment-type/PaymentTypeList";
import PaymentTypeManage from "./pages/payment-type/PaymentTypeManage";
import ProductBrandList from "./pages/product-brand/ProductBrandList";
import ProductBrandManage from "./pages/product-brand/ProductBrandManage";
import ProductCategoryList from "./pages/product-category/ProductCategoryList";
import ProductCategoryManage from "./pages/product-category/ProductCategoryManage";
import ProductGroupList from "./pages/product-group/ProductGroupList";
import ProductGroupManage from "./pages/product-group/ProductGroupManage";
import ProductTypeList from "./pages/product-type/ProductTypeList";
import ProductTypeManage from "./pages/product-type/ProductTypeManage";
import ProductUnitList from "./pages/product-unit/ProductUnitList";
import ProductUnitManage from "./pages/product-unit/ProductUnitManage";
import ProductList from "./pages/product/ProductList";
import ProductManage from "./pages/product/ProductManage";
import PurchaseOrderList from "./pages/purchase-order/PurchaseOrderList";
import PurchaseOrderManage from "./pages/purchase-order/PurchaseOrderManage";
import StockList from "./pages/stock/StockList";
import StockManage from "./pages/stock/StockManage";
import SupplierList from "./pages/supplier/SupplierList";
import SupplierManage from "./pages/supplier/SupplierManage";
import TransportList from "./pages/transport/TransportList";
import TransportManage from "./pages/transport/TransportManage";
import UserList from "./pages/user/UserList";
import UserManage from "./pages/user/UserManage";
import ReceiveList from "./pages/warehouse-management/receive/ReceiveList";
import ReceiveManage from "./pages/warehouse-management/receive/ReceiveManage";
import WarehouseList from "./pages/warehouse/WarehouseList";
import WarehouseManage from "./pages/warehouse/WarehouseManage";

const routes = [
  { path: "/", exact: true, name: "แดชบอร์ด", element: Dashboard },
  {
    path: "/customer",
    exact: true,
    name: "รายการลูกค้า",
    element: CustomerList,
  },
  {
    path: "/customer/manage",
    exact: true,
    name: "จัดการข้อมูลลูกค้า",
    element: CustomerManage,
  },
  {
    path: "/warehouse",
    exact: true,
    name: "รายการคลังสินค้า",
    element: WarehouseList,
  },
  {
    path: "/warehouse/manage",
    exact: true,
    name: "จัดการข้อมูลคลังสินค้า",
    element: WarehouseManage,
  },
  {
    path: "/category",
    exact: true,
    name: "รายการประเภทสินค้า",
    element: ProductCategoryList,
  },
  {
    path: "/category/manage",
    exact: true,
    name: "จัดการข้อมูลประเภทสินค้า",
    element: ProductCategoryManage,
  },
  {
    path: "/unit",
    exact: true,
    name: "รายการหน่วยสินค้า",
    element: ProductUnitList,
  },
  {
    path: "/unit/manage",
    exact: true,
    name: "จัดการข้อมูลหน่วยสินค้า",
    element: ProductUnitManage,
  },
  {
    path: "/brand",
    exact: true,
    name: "รายการยี่ห้อสินค้า",
    element: ProductBrandList,
  },
  {
    path: "/brand/manage",
    exact: true,
    name: "จัดการข้อมูลยี่ห้อสินค้า",
    element: ProductBrandManage,
  },
  {
    path: "/group",
    exact: true,
    name: "รายการกลุ่มสินค้า",
    element: ProductGroupList,
  },
  {
    path: "/group/manage",
    exact: true,
    name: "จัดการข้อมูลกลุ่มสินค้า",
    element: ProductGroupManage,
  },
  {
    path: "/type",
    exact: true,
    name: "รายการชนิดสินค้า",
    element: ProductTypeList,
  },
  {
    path: "/type/manage",
    exact: true,
    name: "จัดการข้อมูลชนิดสินค้า",
    element: ProductTypeManage,
  },
  {
    path: "/product",
    exact: true,
    name: "รายการสินค้า",
    element: ProductList,
  },
  {
    path: "/product/manage",
    exact: true,
    name: "จัดการข้อมูลสินค้า",
    element: ProductManage,
  },
  {
    path: "/supplier",
    exact: true,
    name: "รายการผู้จัดซื้อ",
    element: SupplierList,
  },
  {
    path: "/supplier/manage",
    exact: true,
    name: "จัดการข้อมูลผู้จัดซื้อ",
    element: SupplierManage,
  },
  {
    path: "/company",
    exact: true,
    name: "จัดการข้อมูลบริษัท",
    element: CompanyManage,
  },
  {
    path: "/user",
    exact: true,
    name: "รายการผู้ใช้งาน",
    element: UserList,
  },
  {
    path: "/user/manage",
    exact: true,
    name: "จัดการข้อมูลผู้ใช้งาน",
    element: UserManage,
  },
  {
    path: "/receive",
    exact: true,
    name: "รับสินค้าเข้าคลัง",
    element: ReceiveList,
  },
  {
    path: "/receive/manage",
    exact: true,
    name: "จัดการรับสินค้าเข้าคลัง",
    element: ReceiveManage,
  },
  {
    path: "/stock",
    exact: true,
    name: "เช็คสต๊อก",
    element: StockList,
  },
  {
    path: "/stock/manage",
    exact: true,
    name: "รายละเอียดสต๊อก",
    element: StockManage,
  },
  {
    path: "/purchase-order",
    exact: true,
    name: "ใบสั่งซื้อ",
    element: PurchaseOrderList,
  },
  {
    path: "/purchase-order/manage",
    exact: true,
    name: "จัดการใบสั่งซื้อ",
    element: PurchaseOrderManage,
  },
  {
    path: "/transport",
    exact: true,
    name: "ประเภทการขนส่ง",
    element: TransportList,
  },
  {
    path: "/transport/manage",
    exact: true,
    name: "จัดการประเภทการขนส่ง",
    element: TransportManage,
  },
  {
    path: "/payment-type",
    exact: true,
    name: "ประเภทการชำระ",
    element: PaymentTypeList,
  },
  {
    path: "/payment-type/manage",
    exact: true,
    name: "จัดการประเภทการชำระ",
    element: PaymentTypeManage,
  },
];
export default routes;
