import dayjs from 'dayjs';
import React from 'react';
import { formatNumber, numberToThaiText } from '../../services/helper.service';

const FormToPrint = React.forwardRef((props, ref) => {
    const itemsPerPage = 10;
    const totalItems = props?.prop?.details?.length; // Replace with your dynamic items count
    const pages = Math.ceil(totalItems / itemsPerPage);

    const renderPage = (pageNumber) => {

        const startIdx = pageNumber * itemsPerPage;
        const endIdx = Math.min(startIdx + itemsPerPage, totalItems);
        return (
            <div style={{ pageBreakAfter: 'always', padding: "20px", fontFamily: "Arial, sans-serif", fontSize: "12px" }}>
                {/* Header Section */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", pageBreakInside: 'avoid' }}>
                    <div style={{ textAlign: "left" }}>
                        <h2>{props?.prop?.company?.companyName}</h2>
                        <p>{props?.prop?.company?.address}</p>
                        <p>{props?.prop?.company?.tel}</p>
                        <p>เลขที่ผู้เสียภาษี: {props?.prop?.company?.taxNo}</p>
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <h1>ใบสั่งซื้อ</h1>
                    </div>
                </div>

                {/* Supplier and Order Info */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", pageBreakInside: 'avoid' }}>
                    <div style={{ textAlign: "left" }}>
                        <p>ผู้จัดจำหน่าย: {props?.prop?.supplier?.supplierName}</p>
                        <p>{props?.prop?.supplier?.address}</p>
                        <p>{props?.prop?.supplier?.tel}</p>
                        <p>เลขที่ผู้เสียภาษี: {props?.prop?.supplier?.taxNo}</p>
                        <p>หมายเหตุ: {props?.prop?.remark}</p>
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <p>เลขที่ใบสั่งซื้อ: {props?.prop?.purchaseOrderNo}</p>
                        <p>วันที่สั่งซื้อ: {props?.prop?.purchaseOrderDateText}</p>
                        <p>วันที่รับของ: {props?.prop?.receiveOrderDateText}</p>
                        <p>ประเภทการชำระ: {props?.prop?.paymentTypeName}</p>
                        <p>ประเภทการขนส่ง: {props?.prop?.transportName}</p>
                    </div>
                </div>

                {/* Table Section */}
                <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px", fontSize: '10px' }}>
                    <thead style={{ pageBreakInside: 'avoid' }}>
                        <tr>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>No.</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>รหัสสินค้า/รายละเอียด</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}>จำนวน</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}>ราคา</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}>หมายเหตุ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props?.prop?.details?.slice(startIdx, endIdx).map((_, index) => (
                            <tr key={startIdx + index}>
                                <td style={{ border: "1px solid black", padding: "8px" }}>{startIdx + index + 1}</td>
                                <td style={{ border: "1px solid black", padding: "8px" }}>{_?.product?.productCode} {_?.product?.productName} {formatNumber(_?.product?.productWeight)} {_?.product?.unitName}</td>
                                <td style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}>{formatNumber(_?.total)}</td>
                                <td style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}>{formatNumber(_?.price)}</td>
                                <td style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}>{_?.remark}</td>
                            </tr>
                        ))}
                    </tbody>
                    {(pageNumber === 0 && pages === 1) || (pageNumber === pages - 1 && pages > 1) ?
                        <tfoot>
                            <tr>
                                <td colSpan={3} style={{ border: "1px solid black", borderRight: 'none', textAlign: 'right', padding: "8px" }}>
                                    ราคาสุทธิสินค้าที่เสียภาษี (บาท) / Pre-Vat Amount
                                </td>
                                <td style={{ border: "1px solid black", borderRight: 'none', textAlign: 'right', padding: "8px" }}>
                                    {formatNumber(props?.prop?.details?.reduce((acc, item) => acc + item.price, 0.00))}
                                </td>
                                <td style={{ border: "1px solid black", borderLeft: 'none', textAlign: 'left', padding: "8px" }}>บาท</td>
                            </tr>
                            <tr>
                                <td colSpan={3} style={{ border: "1px solid black", borderRight: 'none', textAlign: 'right', padding: "8px" }}>
                                    ภาษีมูลค่าเพิ่ม (บาท) / VAT {props?.prop?.vat} %
                                </td>
                                <td style={{ border: "1px solid black", borderRight: 'none', textAlign: 'right', padding: "8px" }}>
                                    {props?.prop?.vat > 0 ? formatNumber((props?.prop?.vat / 100) * props?.prop?.details?.reduce((acc, item) => acc + item.price, 0.00)) : 0.00}
                                </td>
                                <td style={{ border: "1px solid black", borderLeft: 'none', textAlign: 'left', padding: "8px" }}>บาท</td>
                            </tr>
                            <tr>
                                <td colSpan={3} style={{ border: "1px solid black", borderRight: 'none', textAlign: 'right', padding: "8px" }}>
                                    จำนวนเงินทั้งสิ้น (บาท) / Grand Total
                                </td>
                                <td style={{ border: "1px solid black", borderRight: 'none', textAlign: 'right', padding: "8px" }}>
                                    {props?.prop?.vat > 0 ? formatNumber(((props?.prop?.vat / 100) * props?.prop?.details?.reduce((acc, item) => acc + item.price, 0.00)) + props?.prop?.details?.reduce((acc, item) => acc + item.price, 0.00))
                                        : formatNumber(props?.prop?.details?.reduce((acc, item) => acc + item.price, 0.00))}
                                </td>
                                <td style={{ border: "1px solid black", borderLeft: 'none', textAlign: 'left', padding: "8px" }}>บาท</td>
                            </tr>
                            <tr>
                                <td colSpan={3} style={{ border: "1px solid black", textAlign: 'right', padding: "8px" }}>
                                    จำนวนเงินรวมทั้งสิ้น
                                </td>
                                <td colSpan={2} style={{ border: "1px solid black", textAlign: 'center', padding: "8px" }}>
                                    {props?.prop?.vat > 0 ? numberToThaiText(((props?.prop?.vat / 100) * props?.prop?.details?.reduce((acc, item) => acc + item.price, 0.00)) + props?.prop?.details?.reduce((acc, item) => acc + item.price, 0.00))
                                        : numberToThaiText(props?.prop?.details?.reduce((acc, item) => acc + item.price, 0.00))}
                                </td>
                            </tr>
                        </tfoot> : <></>
                    }

                </table>

                {/* Signature Section */}
                {(pageNumber === 0 && pages === 1) || (pageNumber === pages - 1 && pages > 1) ?
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px", marginTop: "20px", pageBreakInside: 'avoid' }}>
                        <div style={{ textAlign: "center" }}>
                            <p>ผู้สั่งซื้อ</p>
                            <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                                <p>_____________________</p>
                            </div>
                            <p>(ชื่อผู้สั่งซื้อ)</p>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <p>ผู้อนุมัติ</p>
                            <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                                <p>_____________________</p>
                            </div>
                            <p>(ชื่อผู้อนุมัติ)</p>
                        </div>
                    </div>
                    : <></>}
                {/* Footer */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", pageBreakInside: 'avoid' }}>
                    <p>พิมพ์วันที่: {dayjs().format('YYYY-MM-DD HH:mm:ss')}</p>
                    <p>หน้าที่: {pageNumber + 1} </p>
                </div>
            </div>
        );
    };

    return (
        <div ref={ref}>
            {Array.from({ length: pages }).map((_, pageNumber) => renderPage(pageNumber))}
        </div>
    );
});

export default FormToPrint;