import { Table, Tag, Button, Col, Row, Input, Form, ColorPicker } from "antd";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { deleteProductCategory, getProductCategoryPaging } from "../../services/category.service";
const ProductCategoryList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [sortField, setSortField] = useState()
  const [sortOrder, setSortOrder] = useState()
  const [search, setSearch] = useState()
  const menuCode = ""
  useEffect(() => {
    fetchData({
      currentPage: currentPage,
      sortColumn: sortField ?? '',
      sortDirection: sortOrder ?? '',
      perPage: perPage,
      search: search,
    })
  }, [currentPage, sortField, sortOrder, perPage, search])
  const fetchData = async (param) => {
    let response = await getProductCategoryPaging({
      currentPage: param.currentPage,
      perPage: param.perPage ?? 10,
      sortColumn: param.sortColumn ?? '',
      sortDirection: param.sortDirection ?? '',
      menuCode: menuCode ?? '',
      search: search ?? '',
    })
    if (response?.data) {
      setData(response?.data?.data)
      setTotalRows(response?.data?.total)
    }
  }
  const onChange = (pagination, filters, sorter, extra) => {
    setCurrentPage(pagination?.current)
    setSortField(sorter?.field)
    setSortOrder(sorter?.order)
    setPerPage(pagination?.pageSize);
    setSearch(filters?.search);
  };
  const columns = [
    {
      title: "#",
      width: 150,
      align: "center",
      render: (row) => (
        <div>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => {
              navigate("/category/manage", { state: row });
            }}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
            }}
          />
          <Button
            type="text"
            icon={<DeleteOutlined />}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
              color: "red",
            }}
            onClick={() => {
              Swal.fire({
                title: 'ต้องการลบข้อมูลใช่หรือไม่?',
                text: 'โปรดตรวจสอบและยืนยันว่าต้องการลบข้อมูล จะไม่สามารถนำข้อมูลกลับมาได้!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#040405',
                confirmButtonText: 'ใช่ , ฉันต้องการลบ',
              }).then(async (r) => {
                if (r.isConfirmed) {
                  const { data } = await deleteProductCategory(row.categoryId)
                  if (!data?.isSuccess) {
                    Swal.fire(`ล้มเหลว!`, data?.message, 'error')
                  } else {
                    Swal.fire(`สำเร็จ!`, data?.message, 'success').then((result) => {
                      if (result.isConfirmed) {
                        window.location.reload(false)
                      }
                    })
                  }
                }
              })
            }}
          />
        </div>
      ),
    },
    {
      title: "ชื่อประเภทสินค้า",
      dataIndex: "categoryName",
      key: "categoryName",
      sorter: true,
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      sorter: true,
    },
    // {
    //   title: "สีกลุ่ม",
    //   dataIndex: "color",
    //   key: "color",
    //   sorter: true,
    //   render: (row) => (
    //     <ColorPicker defaultValue={row} disabled showText />
    //   )
    // },
    {
      title: "ใช้งาน",
      dataIndex: "isActive",
      key: "isActive",
      sorter: true,
      render: (row) => (
        <Tag color={row ? "green" : "volcano"}>
          {row ? "ใช้งาน" : "ไม่ใช้งาน"}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <Row className="row-padding-10">
        <Col className="gutter-row" xs={24} lg={12}>
          <Button
            className="ant-btn-submit"
            icon={<PlusCircleOutlined style={{ color: "#FFFFFF" }} />}
            onClick={() => {
              navigate("/category/manage");
            }}
            size="large"
            style={{ color: "#FFFFFF", border: "#555843" }}
          >
            เพิ่ม
          </Button>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label={`ค้นหา`}
            name="searh"
          >
            <Input
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="row-padding-10">
        <Col className="gutter-row" span={24}>
          <Table
            dataSource={data}
            columns={columns}
            scroll={{ x: 500 }}
            onChange={onChange}
            pagination={{
              current: currentPage,
              pageSize: perPage,
              total: totalRows,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ProductCategoryList;
