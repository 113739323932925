import { Table, Tag, Button, Col, Row, Form, Input, Card, Select, Space, DatePicker } from "antd";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import dayjs from 'dayjs';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { getUserSelect } from "../../../services/user.service";
import { deleteReceive, getReceivePaging } from "../../../services/receive.service";
import { getSupplierSelect } from "../../../services/supplier.service";
const { RangePicker } = DatePicker;
const ReceiveList = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [sortField, setSortField] = useState()
  const [sortOrder, setSortOrder] = useState()
  const [search, setSearch] = useState()
  const [dateRange, setDateRange] = useState(null);
  const [supplierList, setSupplierList] = useState([])
  const [supplierId, setSupplierId] = useState()
  const [receiverId, setReceiverId] = useState()
  const [userList, setUserList] = useState([])
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
      md: { span: 14 },
      lg: { span: 14 },
    },
  }
  const menuCode = ""
  useEffect(() => {
    loadDataDropDown();
    fetchData({
      currentPage: currentPage,
      sortColumn: sortField ?? '',
      sortDirection: sortOrder ?? '',
      perPage: perPage,
    })
  }, [currentPage, sortField, sortOrder, perPage])
  const fetchData = async (param) => {
    let response = await getReceivePaging({
      currentPage: param?.currentPage ?? 1,
      perPage: param?.perPage ?? 10,
      sortColumn: param?.sortColumn ?? '',
      sortDirection: param?.sortDirection ?? '',
      menuCode: menuCode ?? '',
      search: param?.isClear ? '' : search ?? '',
      dateStart: param?.isClear ? '' : dateRange?.dateStart ?? '',
      dateEnd: param?.isClear ? '' : dateRange?.dateEnd ?? '',
      supplierId: param?.isClear ? '' : supplierId ?? '',
      receiverId: param?.isClear ? '' : receiverId ?? ''
    })
    if (response?.data) {
      setData(response?.data?.data)
      setTotalRows(response?.data?.total)
    }
  }

  const columns = [
    {
      title: "#",
      width: 100,
      align: "center",
      render: (row) => (
        <div>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => {
              navigate("/receive/manage", { state: row });
            }}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
            }}
          />
          <Button
            type="text"
            icon={<DeleteOutlined />}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
              color: "red",
            }}
            onClick={() => {
              Swal.fire({
                title: 'ต้องการลบข้อมูลใช่หรือไม่?',
                text: 'โปรดตรวจสอบและยืนยันว่าต้องการลบข้อมูล จะไม่สามารถนำข้อมูลกลับมาได้!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#040405',
                confirmButtonText: 'ใช่ , ฉันต้องการลบ',
              }).then(async (r) => {
                if (r.isConfirmed) {
                  const { data } = await deleteReceive(row.receiveId)
                  if (!data?.isSuccess) {
                    Swal.fire(`ล้มเหลว!`, data?.message, 'error')
                  } else {
                    Swal.fire(`สำเร็จ!`, data?.message, 'success').then((result) => {
                      if (result.isConfirmed) {
                        window.location.reload(false)
                      }
                    })
                  }
                }
              })
            }}
          />
        </div>
      ),
    },
    {
      title: "เลขที่เอกสาร",
      dataIndex: "receiveNo",
      key: "receiveNo",
      sorter: true,
      width: 200,
    },
    {
      title: "วันที่รับสินค้า",
      dataIndex: "receiveDateText",
      key: "receiveDateText",
      sorter: true,
      width: 200,
    },
    {
      title: "เลขที่เอกสารอ้างอิง",
      dataIndex: "receiveRef",
      key: "ReceiveRef",
      sorter: true,
      width: 200,
    },
    {
      title: "ผู้จัดซื้อ",
      dataIndex: "supplierName",
      key: "supplierName",
      sorter: true,
      width: 250,
    },
    {
      title: "ผู้รับสินค้า",
      dataIndex: "receiverName",
      key: "receiverName",
      sorter: true,
      width: 250,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      sorter: true,
      width: 250,
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
      width: 200,
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    setCurrentPage(pagination?.current)
    setSortField(sorter?.field)
    setSortOrder(sorter?.order)
    setPerPage(pagination?.pageSize);
    setSearch(filters?.search);
  };
  const onFinish = async (data) => {
    fetchData()
  }
  const loadDataDropDown = async () => {
    const { data: user } = await getUserSelect()
    setUserList(user)
    const { data: supplier } = await getSupplierSelect()
    if (supplier) {
      setSupplierList(supplier)
    }
  }
  const handleDateChange = (dates, dateStrings) => {
    const [start, end] = dates || [null, null]; // Handle null in case of clear
    setDateRange({
      dateStart: start ? dayjs(start).format('YYYY-MM-DD') : null,
      dateEnd: end ? dayjs(end).format('YYYY-MM-DD') : null,
    });
  };
  return (
    <>
      <Row className="row-padding-10">
        <Col className="gutter-row" span={24}>
          <Card title="ค้นหา" bordered={false}>
            <Form form={form} {...formItemLayout} onFinish={onFinish}>
              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`วันที่`}
                    name="dateRange"
                  >
                    <RangePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} onChange={handleDateChange} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`ค้นหา`}
                    name="search"
                  >
                    <Input
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      placeholder="ค้นหา เลขที่เอกสาร , เลขที่เอกสารอ้างอิง , หมายเหตุ"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`ผู้จัดซื้อ`}
                    name="supplierId"
                  >
                    <Select
                      placeholder="กรุณาเลือก"
                      style={{ width: "100%" }}
                      options={supplierList}
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => setSupplierId(e)}
                      value={supplierId}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`ผู้รับสินค้า`}
                    name="receiverId"
                  >
                    <Select
                      placeholder="กรุณาเลือก"
                      style={{ width: "100%" }}
                      options={userList}
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => setReceiverId(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="mt-3">
                <Col span={24}>
                  <div style={{ textAlign: "center" }}>
                    <Space size="middle">
                      <Button
                        htmlType="submit"
                        style={{ backgroundColor: "#125a05", color: '#FFFFFF' }}
                        size="large"
                      >
                        ค้นหา
                      </Button>
                      <Button
                        type="primary"
                        danger
                        size="large"
                        onClick={() => {
                          form.setFieldsValue({
                            supplierId: null,
                            receiverId: null,
                            search: null,
                            dateRange: null,
                          });
                          setSupplierId(null)
                          setReceiverId(null)
                          setSearch(null)
                          setDateRange(null)

                          fetchData({ isClear: true })
                        }}
                      >
                        ล้าง
                      </Button>
                    </Space>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row className="row-padding-10">
        <Col className="gutter-row" xs={24} lg={12}>
          <Button
            className="ant-btn-submit"
            icon={<PlusCircleOutlined style={{ color: "#FFFFFF" }} />}
            onClick={() => {
              navigate("/receive/manage");
            }}
            size="large"
            style={{ color: "#FFFFFF", border: "#555843" }}
          >
            เพิ่ม
          </Button>
        </Col>
      </Row>
      <Row className="row-padding-10">
        <Col className="gutter-row" span={24}>
          <Table
            dataSource={data}
            columns={columns}
            scroll={{ x: 500 }}
            onChange={onChange}
            pagination={{
              current: currentPage,
              pageSize: perPage,
              total: totalRows,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ReceiveList;
