import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Select,
  Switch,
  Space,
  Button,
  DatePicker,
} from "antd";
import Barcode from "react-barcode";
import { getProductCategorySelect } from "../../services/category.service";
import { getProductUnitSelect } from "../../services/unit.service";
import Swal from 'sweetalert2'
import { createProduct, getProductById, updateProduct } from "../../services/product.service";
import { handleKeyDecimalPress, handleKeyPress } from "../../services/helper.service";
import { getProductGroupSelect } from "../../services/group.service";
import { getProductTypeSelect } from "../../services/type.service";
import { getSupplierSelect } from "../../services/supplier.service";
import { getProductBrandSelect } from "../../services/brand.service";
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 14 },
  },
};

const ProductManage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const onFinish = async (data) => {
    let timerInterval;
    var param = {
      productId: productId ?? "",
      // productCode: data?.productCode,
      productName: data?.productName,
      categoryId: data?.categoryId,
      unitId: data?.unitId,
      detail: data?.detail,
      isActive: isActive,
      typeId: data?.typeId,
      supplierId: data?.supplierId,
      productCommonName: data?.productCommonName,
      productSubstances: data?.productSubstances,
      productRegisterCode: data?.productRegisterCode,
      productKeyIngredinents: data?.productKeyIngredinents,
      productUsage: data?.productUsage,
      productHowto: data?.productHowto,
      productCautions: data?.productCautions,
      productPackaging: data?.productPackaging,
      productWeight: data?.productWeight,
      productFormula: data?.productFormula,
      productSecElements: data?.productSecElements,
      brandId: data?.brandId,
      productSize: data?.productSize,
      productGroupId: data?.productGroupId,
    }
    if (location?.state?.categoryId) {
      const { data: updateData } = await updateProduct(param);
      if (updateData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            window.location.reload()
          }
        })
      } else {
        Swal.fire(updateData?.message, '', 'error')
      }
    } else {
      const { data: insertData } = await createProduct(param);
      if (insertData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            setProductId(insertData?.id)
            navigate("/product/manage", { state: { productId: insertData?.id } });
            window.location.reload()
          }
        })
      } else {
        Swal.fire(insertData?.message, '', 'error')
      }
    }
  };
  const [productId, setProductId] = useState();
  const [supplierList, setSupplierList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [isActive, setIsActive] = useState(true);

  const loadDropdownList = async () => {
    const { data: category } = await getProductCategorySelect()
    if (category) {
      setCategoryList(category)
    }
    const { data: unit } = await getProductUnitSelect()
    if (unit) {
      setUnitList(unit)
    }
    const { data: group } = await getProductGroupSelect()
    if (group) {
      setGroupList(group)
    }
    const { data: type } = await getProductTypeSelect()
    if (type) {
      setTypeList(type)
    }
    // const { data: supplier } = await getSupplierSelect()
    // if (supplier) {
    //   setSupplierList(supplier)
    // }
    const { data: brand } = await getProductBrandSelect()
    if (brand) {
      setBrandList(brand)
    }
  }
  const loadData = async (param) => {
    const { data } = await getProductById(param)
    if (data) {
      setIsActive(data.isActive)
      form.setFieldsValue({
        productId: data?.productId,
        productCode: data?.productCode,
        productName: data?.productName,
        detail: data?.detail,
        categoryId: data?.categoryId,
        unitId: data?.unitId,
        typeId: data?.typeId,
        // supplierId: data?.supplierId,
        productCommonName: data?.productCommonName,
        productSubstances: data?.productSubstances,
        productRegisterCode: data?.productRegisterCode,
        productKeyIngredinents: data?.productKeyIngredinents,
        productUsage: data?.productUsage,
        productHowto: data?.productHowto,
        productCautions: data?.productCautions,
        productPackaging: data?.productPackaging,
        productWeight: data?.productWeight,
        productFormula: data?.productFormula,
        productSecElements: data?.productSecElements,
        brandId: data?.brandId,
        productSize: data?.productSize,
        productGroupId: data?.productGroupId,
      });
    }
  }
  useEffect(() => {
    loadDropdownList()
    if (location?.state?.productId) {
      setProductId(location?.state?.productId)
      loadData(location?.state?.productId)
    } else {
      setIsActive(true)
    }
  }, []);

  return (
    <>
      <Card title="จัดการข้อมูลสินค้า" bordered={false}>
        <Form form={form} {...formItemLayout} onFinish={onFinish}>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`กลุ่มสินค้า`}
                name="productGroupId"
                rules={[{ required: true, message: "กรุณาเลือกกลุ่มสินค้า" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={groupList}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`รหัสสินค้า`}
                name="productCode"
              // rules={[{ required: true, message: "กรุณากรอกรหัสสินค้า" }]}
              >
                <Input
                  maxLength={20}
                  readOnly className="read-only"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชื่อสินค้า`}
                name="productName"
                rules={[{ required: true, message: "กรุณากรอกชื่อสินค้า" }]}
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชื่อสามัญ`}
                name="productCommonName"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ประเภทสินค้า`}
                name="categoryId"
                rules={[{ required: true, message: "กรุณาเลือกประเภทสินค้า" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={categoryList}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชนิด`}
                name="typeId"
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={typeList}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label={`ปริมาณ`} name="productWeight" rules={[{ required: true, message: "กรุณากรอกปริมาณ" }]}>
                <Input onKeyPress={handleKeyDecimalPress} placeholder="0.00" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`หน่วยสินค้า`}
                name="unitId"
                rules={[{ required: true, message: "กรุณาเลือกหน่วยสินค้า" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={unitList}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`สารสำคัญ`}
                name="productKeyIngredinents"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`กลุ่มสาร`}
                name="productSubstances"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ธาตุรอง`}
                name="productSecElements"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`สูตร`}
                name="productFormula"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`วิธีใช้`}
                name="productHowto"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ข้อควรระวัง`}
                name="productCautions"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`บรรจุต่อลัง/ชิ้น`}
                name="productPackaging"
              >
                <Input onKeyPress={handleKeyPress} placeholder="0" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ขนาด`}
                name="productSize"
              >
                <Input maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ทะเบียน`}
                name="productRegisterCode"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ยี่ห้อ`}
                name="brandId"
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={brandList}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ประโยชน์`}
                name="productUsage"
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`ใช้งาน`}>
                <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
              </Form.Item>
            </Col>
            {/* <Col xs={24} lg={12}>
              <Form.Item
                label={`ผู้จัดซื้อ`}
                name="supplierId"
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={supplierList}
                />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label={`รายละเอียดอื่นๆ`} name="detail">
                <TextArea
                  rows={4}
                />
              </Form.Item>
            </Col>

          </Row>
          {/* <Row gutter={24}> */}
          {/* <Col xs={24} lg={12}>
              {productCode ? (
                <Form.Item label={`Barcode`}>
                  <Barcode value={productCode} height={50} fontSize={10}
                    bgColor="#fff" style={{ margin: "10px", padding: 0 }}
                    renderer="img"
                  />
                </Form.Item>
              ) : (
                <></>
              )}
            </Col> */}
          {/* </Row> */}
          <Row gutter={16} className="mt-3">
            <Col span={24}>
              <div style={{ textAlign: "center" }}>
                <Space size="middle">
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    กลับ
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#125a05" }}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};
export default ProductManage;
