import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'productType/'
const getProductTypePaging = async (param) => {
    return await axios.get(
        API_URL +
        'GetPaging?currentPage=' +
        param?.currentPage +
        '&perPage=' +
        param?.perPage +
        '&sortColumn=' +
        param?.sortColumn +
        '&sortDirection=' +
        param?.sortDirection +
        '&search=' +
        param?.search +
        '&menuCode=' +
        param?.menuCode,
        { headers: authHeader() },
    )
}
const createProductType = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateProductType = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deleteProductType = async (param) => {
    return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getProductTypeById = async (id) => {
    return await axios.get(API_URL + 'GetById?id=' + id, { headers: authHeader() })
}
const getProductTypeSelect = async () => {
    return await axios.get(API_URL + 'GetSelect', { headers: authHeader() })
}
export {
    getProductTypePaging,
    createProductType,
    updateProductType,
    deleteProductType,
    getProductTypeById,
    getProductTypeSelect
}