import { Table, Tag, Button, Col, Row, Input, Form, Card, Select, Space } from "antd";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { deleteProduct, getProductPaging } from "../../services/product.service";
import Barcode from "react-barcode";
import { getProductCategorySelect } from "../../services/category.service";
import { getProductUnitSelect } from "../../services/unit.service";
import { getProductGroupSelect } from "../../services/group.service";
import { getProductTypeSelect } from "../../services/type.service";
import { getProductBrandSelect } from "../../services/brand.service";
const ProductList = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [sortField, setSortField] = useState()
  const [sortOrder, setSortOrder] = useState()
  const [search, setSearch] = useState()
  const [categoryId, setCategoryId] = useState()
  const [unitId, setUnitId] = useState()
  const [groupId, setGroupId] = useState()
  const [brandId, setBrandId] = useState()
  const [typeId, setTypeId] = useState()
  const [categoryList, setCategoryList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const menuCode = ""
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
      md: { span: 14 },
      lg: { span: 14 },
    },
  }
  const clear = () => {
    setCurrentPage(1)
    setBrandId(null)
    setCategoryId(null)
    setGroupId(null)
    setSearch('')
    setBrandId(null)
    setTypeId(null)
    setUnitId(null)
    form.setFieldsValue({
      groupId: null,
      categoryId: null,
      unitId: null,
      typeId: null,
      supplierId: null,
      brandId: null,
      search: '',
    });
    fetchData({ isClear: true })
  }
  const loadDropdownList = async () => {
    const { data: category } = await getProductCategorySelect()
    if (category) {
      setCategoryList(category)
    }
    const { data: unit } = await getProductUnitSelect()
    if (unit) {
      setUnitList(unit)
    }
    const { data: group } = await getProductGroupSelect()
    if (group) {
      setGroupList(group)
    }
    const { data: type } = await getProductTypeSelect()
    if (type) {
      setTypeList(type)
    }
    // const { data: supplier } = await getSupplierSelect()
    // if (supplier) {
    //   setSupplierList(supplier)
    // }
    const { data: brand } = await getProductBrandSelect()
    if (brand) {
      setBrandList(brand)
    }
  }
  useEffect(() => {
    loadDropdownList()
    fetchData({
      currentPage: currentPage,
      sortColumn: sortField ?? '',
      sortDirection: sortOrder ?? '',
      perPage: perPage,
    })
  }, [currentPage, sortField, sortOrder, perPage])
  const fetchData = async (param) => {
    let response = await getProductPaging({
      currentPage: param?.currentPage ?? 1,
      perPage: param?.perPage ?? 10,
      sortColumn: param?.sortColumn ?? '',
      sortDirection: param?.sortDirection ?? '',
      menuCode: menuCode ?? '',
      search: param?.isClear ? '' : search ?? '',
      categoryId: param?.isClear ? '' : categoryId ?? '',
      unitId: param?.isClear ? '' : unitId ?? '',
      productGroupId: param?.isClear ? '' : groupId ?? '',
      typeId: param?.isClear ? '' : typeId ?? '',
      // supplierId: supplierId ?? '',
      brandId: param?.isClear ? '' : brandId ?? ''
    })
    if (response?.data) {
      setData(response?.data?.data)
      setTotalRows(response?.data?.total)
    }
  }
  const onChange = (pagination, filters, sorter, extra) => {
    setCurrentPage(pagination?.current)
    setSortField(sorter?.field)
    setSortOrder(sorter?.order)
    setPerPage(pagination?.pageSize);
  };
  const columns = [
    {
      title: "#",
      width: 120,
      align: "center",
      render: (row) => (
        <div>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => {
              navigate("/product/manage", { state: row });
            }}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
            }}
          />
          <Button
            type="text"
            icon={<DeleteOutlined />}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
              color: "red",
            }}
            onClick={() => {
              Swal.fire({
                title: 'ต้องการลบข้อมูลใช่หรือไม่?',
                text: 'โปรดตรวจสอบและยืนยันว่าต้องการลบข้อมูล จะไม่สามารถนำข้อมูลกลับมาได้!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#040405',
                confirmButtonText: 'ใช่ , ฉันต้องการลบ',
              }).then(async (r) => {
                if (r.isConfirmed) {
                  const { data } = await deleteProduct(row.productId)
                  if (!data?.isSuccess) {
                    Swal.fire(`ล้มเหลว!`, data?.message, 'error')
                  } else {
                    Swal.fire(`สำเร็จ!`, data?.message, 'success').then((result) => {
                      if (result.isConfirmed) {
                        window.location.reload(false)
                      }
                    })
                  }
                }
              })
            }}
          />
        </div>
      ),
    },
    {
      title: "Barcode",
      dataIndex: "productCode",
      key: "productCode",
      width: 200,
      render: (_, { productCode }) => (
        <Barcode value={productCode} height={20} width={1} fontSize={10} bgColor="#fff" style={{ margin: "10px", padding: 0 }} />
      ),
    },
    {
      title: "รหัสสินค้า",
      dataIndex: "productCode",
      key: "productCode",
      sorter: true,
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "productName",
      key: "productName",
      sorter: true,
    },
    {
      title: "กลุ่มสินค้า",
      dataIndex: "groupName",
      key: "groupName",
      sorter: true,
    },
    {
      title: "ประเภทสินค้า",
      dataIndex: "categoryName",
      key: "categoryName",
      sorter: true,
    },
    {
      title: "ชนิด",
      dataIndex: "typeName",
      key: "typeName",
      sorter: true,
    },
    {
      title: "ปริมาณสุทธิ",
      dataIndex: "productWeight",
      key: "productWeight",
      sorter: true,
    },
    {
      title: "หน่วยสินค้า",
      dataIndex: "unitName",
      key: "unitName",
      sorter: true,
    },
    // {
    //   title: "ผู้จัดซื้อ",
    //   dataIndex: "supplierName",
    //   key: "supplierName",
    //   sorter: true,
    // },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      sorter: true,
    },
    {
      title: "ใช้งาน",
      dataIndex: "isActive",
      key: "isActive",
      sorter: true,
      render: (row) => (
        <Tag color={row ? "green" : "volcano"}>
          {row ? "ใช้งาน" : "ไม่ใช้งาน"}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <Row className="row-padding-10">
        <Col className="gutter-row" span={24}>
          <Card title="ค้นหา" bordered={false}>
            <Form form={form} {...formItemLayout}>
              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`กลุ่มสินค้า`}
                    name="groupId"
                  >
                    <Select
                      placeholder="กรุณาเลือก"
                      style={{ width: "100%" }}
                      options={groupList}
                      onChange={(e) => setGroupId(e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`ประเภทสินค้า`}
                    name="categoryId"
                  >
                    <Select
                      placeholder="กรุณาเลือก"
                      style={{ width: "100%" }}
                      options={categoryList}
                      onChange={(e) => setCategoryId(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`ชนิด`}
                    name="typeId"
                  >
                    <Select
                      placeholder="กรุณาเลือก"
                      style={{ width: "100%" }}
                      options={typeList}
                      onChange={(e) => setTypeId(e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`หน่วยสินค้า`}
                    name="unitId"
                  >
                    <Select
                      placeholder="กรุณาเลือก"
                      style={{ width: "100%" }}
                      options={unitList}
                      onChange={(e) => setUnitId(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`ค้นหา`}
                    name="search"
                  >
                    <Input
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="ค้นหา รหัสสินค้า , ชื่อสินค้า , รายละเอียด"
                      value={search}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`ยี่ห้อ`}
                    name="brandId"
                  >
                    <Select
                      placeholder="กรุณาเลือก"
                      style={{ width: "100%" }}
                      options={brandList}
                      onChange={(e) => setBrandId(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="mt-3">
                <Col span={24}>
                  <div style={{ textAlign: "center" }}>
                    <Space size="middle">
                      <Button
                        type="primary"
                        style={{ backgroundColor: "#125a05" }}
                        size="large"
                        onClick={() => {
                          fetchData()
                        }}
                      >
                        ค้นหา
                      </Button>
                      <Button
                        type="primary"
                        danger
                        size="large"
                        onClick={() => clear()}
                      >
                        ล้าง
                      </Button>
                    </Space>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row className="row-padding-10">
        <Col className="gutter-row" span={24}>
          <Button
            className="ant-btn-submit"
            icon={<PlusCircleOutlined style={{ color: "#FFFFFF" }} />}
            onClick={() => {
              navigate("/product/manage");
            }}
            size="large"
            style={{ color: "#FFFFFF", border: "#555843" }}
          >
            เพิ่ม
          </Button>
        </Col>
      </Row>
      <Row className="row-padding-10">
        <Col className="gutter-row" span={24}>
          <Table
            dataSource={data}
            columns={columns}
            scroll={{ x: 500 }}
            onChange={onChange}
            pagination={{
              current: currentPage,
              pageSize: perPage,
              total: totalRows,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ProductList;
