import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Col,
    Row,
    Card,
    Form,
    Input,
    Space,
    Button,
    DatePicker,
    Select,
    Table,
    InputNumber
} from "antd";
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'
import {
    DeleteOutlined,
    PlusCircleOutlined
} from "@ant-design/icons";
import { handleKeyDecimalPress, handleKeyPress } from "../../../services/helper.service";
import { getWarehouseSelect } from "../../../services/warehouse.service";
import { getSupplierSelect } from "../../../services/supplier.service";
import { getUserSelect } from "../../../services/user.service";
import { getProductSelect } from "../../../services/product.service";
import { createReceive, getReceiveById, updateReceive } from "../../../services/receive.service";
import dayjs from "dayjs";
import locale from 'dayjs/plugin/localeData'; // Import the localeData plugin
import 'dayjs/locale/th'; // Import the desired locale (e.g., Thai)

// Extend Day.js with the localeData plugin
dayjs.extend(locale);
const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 6 },
        lg: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
        md: { span: 14 },
        lg: { span: 14 },
    },
};
const ReceiveManage = () => {
    dayjs.locale('th');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [detail, setDetail] = useState([])
    const [warehouseList, setWarehouseList] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [userList, setUserList] = useState([])
    const [productList, setProductList] = useState([])
    const addHandle = () => {
        const newDataObject = {
            receiveDetailId: uuidv4(),
            productId: null,
            serialNumber: null,
            lotNo: null,
            warehouseId: null,
            expiredDate: null,
            line: null,
            rack: null,
            loc: null,
            total: null,
            costPrice: null,
            costShop: null,
            retailPrice: null,
            farmerPrice: null,
        };

        setDetail([...detail, newDataObject]);
    }
    const deleteRowById = (idToDelete) => {
        // Filter out the row with the specified ID
        const updatedData = detail?.filter(item => item.receiveDetailId !== idToDelete.receiveDetailId);
        setDetail(updatedData);
    };
    const loadDataDropDown = async () => {
        const { data: warehouse } = await getWarehouseSelect()
        setWarehouseList(warehouse)

        const { data: supplier } = await getSupplierSelect()
        setSupplierList(supplier)

        const { data: user } = await getUserSelect()
        setUserList(user)

        const { data: product } = await getProductSelect()
        setProductList(product)
    }
    const handleDetailChange = (value, fieldName, id) => {
        let d = detail.find((value) => value.receiveDetailId === id)
        if (fieldName === 'productId') {
            d.productId = value
        }
        else if (fieldName === 'serialNumber') {
            d.serialNumber = value
        }
        else if (fieldName === 'lotNo') {
            d.lotNo = value
        }
        else if (fieldName === 'expiredDate') {
            d.expiredDate = value
        }
        else if (fieldName === 'warehouseId') {
            d.warehouseId = value
        } else if (fieldName === 'line') {
            d.line = value
        }
        else if (fieldName === 'rack') {
            d.rack = value
        } else if (fieldName === 'loc') {
            d.loc = value
        } else if (fieldName === 'total') {
            d.total = value ?? 0
        } else if (fieldName === 'costPrice') {
            d.costPrice = value ?? 0
        }
        else if (fieldName === 'retailPrice') {
            d.retailPrice = value ?? 0
        } else if (fieldName === 'costShop') {
            d.costShop = value ?? 0
        } else if (fieldName === 'farmerPrice') {
            d.farmerPrice = value ?? 0
        }

        setDetail([...detail])
    };
    const columns = [
        {
            title: "#",
            width: 50,
            align: "center",
            render: (row) => (
                <div>
                    <Button
                        key={row.receiveDetailId}
                        type="text"
                        icon={<DeleteOutlined />}
                        style={{
                            fontSize: "16px",
                            width: 50,
                            height: 50,
                            color: "red",
                        }}
                        onClick={() => {
                            deleteRowById(row)
                        }}
                    />
                </div>
            ),
        },
        {
            title: "รายละเอียดสินค้า",
            dataIndex: "productId",
            key: "productId",
            sorter: false,
            width: 500,
            render: (text, record) => (
                <Select
                    placeholder="กรุณาเลือก"
                    key={'productId_' + record.receiveDetailId}
                    style={{ width: 'auto%', minWidth: 500, marginRight: 5 }}
                    onChange={(e) => handleDetailChange(e, 'productId', record.receiveDetailId)}
                    options={productList}
                    value={record?.productId}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    optionFilterProp="children"
                    showSearch
                />
            )
        },
        {
            title: "Serial Number",
            dataIndex: "serialNumber",
            key: "serialNumber",
            sorter: false,
            width: 200,
            render: (text, record) => (
                <Input maxLength={50} key={'serialNumber_' + record.receiveDetailId} value={record?.serialNumber} onChange={(e) => { handleDetailChange(e.target.value, 'serialNumber', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "Lot No.",
            dataIndex: "lotNo",
            key: "lotNo",
            sorter: false,
            width: 150,
            render: (text, record) => (
                <Input maxLength={50} key={'lotNo_' + record.receiveDetailId} value={record?.lotNo} onChange={(e) => { handleDetailChange(e.target.value, 'lotNo', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "วันหมดอายุ",
            dataIndex: "expiredDate",
            key: "expiredDate",
            sorter: false,
            width: 150,
            render: (text, record) => (
                <DatePicker style={{ width: 'auto', minWidth: 150, marginRight: 5 }} placeholder="yyyy-MM-dd" value={record?.expiredDate ? dayjs(record?.expiredDate) : null} onChange={(date, dateString) => {
                    handleDetailChange(dateString, 'expiredDate', record.receiveDetailId);
                }} />
            )
        },
        {
            title: "คลัง",
            dataIndex: "warehouseId",
            key: "warehouseId",
            sorter: false,
            width: 200,
            render: (text, record) => (
                <Select
                    placeholder="กรุณาเลือก"
                    key={'warehouseId_' + record.receiveDetailId}
                    style={{ width: 200, marginRight: 5 }}
                    onChange={(e) => handleDetailChange(e, 'warehouseId', record.receiveDetailId)}
                    options={warehouseList}
                    value={record?.warehouseId}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    optionFilterProp="children"
                    showSearch
                />
            )
        },
        {
            title: "แถว",
            dataIndex: "line",
            key: "line",
            sorter: false,
            width: 100,
            render: (text, record) => (
                <Input maxLength={20} key={'line_' + record.receiveDetailId} value={record?.line} onChange={(e) => { handleDetailChange(e.target.value, 'line', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "ชั้น",
            dataIndex: "rack",
            key: "rack",
            sorter: false,
            width: 100,
            render: (text, record) => (
                <Input maxLength={20} key={'rack_' + record.receiveDetailId} value={record?.rack} onChange={(e) => { handleDetailChange(e.target.value, 'rack', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "ล็อค",
            dataIndex: "loc",
            key: "loc",
            sorter: false,
            width: 100,
            render: (text, record) => (
                <Input maxLength={20} key={'loc_' + record.receiveDetailId} value={record?.loc} onChange={(e) => { handleDetailChange(e.target.value, 'loc', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "จำนวน",
            dataIndex: "total",
            key: "total",
            sorter: false,
            width: 150,
            render: (text, record) => (
                <InputNumber
                    min={0}
                    key={`total_${record.receiveDetailId}`}
                    value={record?.total}
                    onChange={(value) => handleDetailChange(value, 'total', record.receiveDetailId)}
                    style={{ minWidth: 140, textAlign: 'right', marginRight: 5 }}
                />
            )
        },
        {
            title: "ราคาทุน",
            dataIndex: "costPrice",
            key: "costPrice",
            sorter: false,
            width: 150,
            render: (text, record) => (
                <Input
                    key={`costPrice_${record.receiveDetailId}`}
                    value={record?.costPrice}
                    onChange={(e) => handleDetailChange(e.target.value, 'costPrice', record.receiveDetailId)}
                    style={{ width: 'auto', minWidth: 140, textAlign: 'right', marginRight: 5 }}
                    onKeyPress={handleKeyDecimalPress}
                />
            )
        },
        {
            title: "ราคาส่งร้านค้า",
            dataIndex: "costShop",
            key: "costShop",
            sorter: false,
            width: 150,
            render: (text, record) => (
                <Input
                    key={`costShop_${record.receiveDetailId}`}
                    value={record?.costShop}
                    onChange={(e) => handleDetailChange(e.target.value, 'costShop', record.receiveDetailId)}
                    style={{ width: 'auto', minWidth: 140, textAlign: 'right', marginRight: 5 }}
                    onKeyPress={handleKeyDecimalPress}
                />
            )
        },
        {
            title: "ราคาส่งเกษตรกร",
            dataIndex: "farmerPrice",
            key: "farmerPrice",
            sorter: false,
            width: 150,
            render: (text, record) => (
                <Input
                    key={`farmerPrice_${record.receiveDetailId}`}
                    value={record?.farmerPrice}
                    onChange={(e) => handleDetailChange(e.target.value, 'farmerPrice', record.receiveDetailId)}
                    style={{ width: 'auto', minWidth: 140, textAlign: 'right', marginRight: 5 }}
                    onKeyPress={handleKeyDecimalPress}
                />
            )
        },
        {
            title: "ราคาปลีก",
            dataIndex: "retailPrice",
            key: "retailPrice",
            sorter: false,
            width: 150,
            render: (text, record) => (
                <Input
                    key={`retailPrice_${record.receiveDetailId}`}
                    value={record?.retailPrice}
                    onChange={(e) => handleDetailChange(e.target.value, 'retailPrice', record.receiveDetailId)}
                    style={{ width: 'auto', minWidth: 140, textAlign: 'right', marginRight: 5 }}
                    onKeyPress={handleKeyDecimalPress}
                />
            )
        },
    ];
    const onFinish = async (data) => {
        if (detail.length === 0) {
            Swal.fire('กรุณาเพิ่มรายละเอียดการจัดรับสินค้าอย่างน้อย 1 รายการ', '', 'error')
        }
        else if (detail.find(x => x.productId === null)) {
            Swal.fire('กรุณาเลือกสินค้ารายละเอียด', '', 'error')
        } else if (detail.find(x => x.warehouseId === null)) {
            Swal.fire('กรุณาเลือกคลังในรายละเอียด', '', 'error')
        } else if (detail.find(x => x.total === null || x.total === '' || x.total === 0)) {
            Swal.fire('กรุณากรอกจำนวนในรายละเอียด', '', 'error')
        } else if (detail.find(x => x.costPrice === null || x.costPrice === '' || x.costPrice === 0)) {
            Swal.fire('กรุณากรอกราคาต่อหน่วยในรายละเอียด', '', 'error')
        }
        else {

            let timerInterval;
            var param = {
                receiveId: receiveId ?? "",
                receiveDate: data.receiveDate ? dayjs(data.receiveDate).format('YYYY-MM-DDTHH:mm:ss') : null,
                supplierId: data.supplierId,
                receiveRef: data.receiveRef,
                receiverId: data.receiverId,
                remark: data.remark,
                details: detail,
            }

            if (location?.state?.receiveId) {
                const { data: updateData } = await updateReceive(param);
                if (updateData.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'บันทึกข้อมูล',
                        text: 'บันทึกสำเร็จ',
                        html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
                        timer: 500,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            window.location.reload()
                        }
                    })
                } else {
                    Swal.fire(updateData?.message, '', 'error')
                }
            } else {
                const { data: insertData } = await createReceive(param);
                if (insertData.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'บันทึกข้อมูล',
                        text: 'บันทึกสำเร็จ',
                        html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
                        timer: 500,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            setReceiveId(insertData?.id)
                            navigate("/receive/manage", { state: { receiveId: insertData?.id } });
                            window.location.reload()
                        }
                    })
                } else {
                    Swal.fire(insertData?.message, '', 'error')
                }
            }
        }
    };
    const [receiveId, setReceiveId] = useState();
    const loadData = async (param) => {
        const { data } = await getReceiveById(param)
        if (data) {
            form.setFieldsValue({
                receiveNo: data.receiveNo,
                receiveDate: dayjs(data.receiveDate),
                supplierId: data.supplierId,
                receiveRef: data.receiveRef,
                receiverId: data.receiverId,
                receiveId: data.receiveId,
                remark: data.remark,
            });
            setReceiveId(data.receiveId)
            setDetail(data?.details)
        }
    }
    useEffect(() => {
        loadDataDropDown()
        if (location?.state?.receiveId) {
            loadData(location?.state?.receiveId)
        }
    }, []);
    return (
        <>
            <Card title="จัดการรับสินค้าเข้าคลัง" bordered={false}>
                <Form form={form} {...formItemLayout} onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={`เลขที่เอกสาร`}
                                name="receiveNo"
                            >
                                <Input readOnly className="read-only" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={`วันที่รับสินค้า`}
                                name="receiveDate"
                                rules={[{ required: true, message: "กรุณาเลือกวันที่รับสินค้า" }]}
                            >
                                <DatePicker showTime style={{ width: '100%' }} placeholder="yyyy-MM-dd HH:mm:ss" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`ผู้จัดซื้อ`} name="supplierId"
                                rules={[{ required: true, message: "กรุณาเลือกผู้จัดซื้อ" }]}
                            >
                                <Select
                                    placeholder="กรุณาเลือก"
                                    style={{ width: "100%" }}
                                    options={supplierList}
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    optionFilterProp="children"
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={`เลขที่เอกสารอ้างอิง`}
                                name="receiveRef"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณากรอกเลขที่เอกสารอ้างอิง",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`ผู้รับสินค้า`} name="receiverId"
                                rules={[{ required: true, message: "กรุณาเลือกผู้รับสินค้า" }]}
                            >
                                <Select
                                    placeholder="กรุณาเลือก"
                                    style={{ width: "100%" }}
                                    options={userList}
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    optionFilterProp="children"
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`หมายเหตุ`} name="remark">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col className="gutter-row" xs={24} lg={24}>
                            <Button
                                className="ant-btn-submit"
                                icon={<PlusCircleOutlined style={{ color: "#FFFFFF" }} />}
                                onClick={() =>
                                    addHandle()
                                }
                                size="large"
                                style={{ color: "#FFFFFF", border: "#555843" }}
                            >
                                เพิ่ม
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: '10px' }}>
                        <Col className="gutter-row" span={24}>
                            <div style={{ width: "100%", overflowX: "auto" }}>
                                <Table columns={columns} scroll={{ x: 500 }} dataSource={detail} />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ marginTop: '10px' }}>
                        <Col span={24}>
                            <div style={{ textAlign: "center" }}>
                                <Space size="middle">
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        กลับ
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ backgroundColor: "#125a05" }}
                                    >
                                        ยืนยัน
                                    </Button>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card >
        </>
    );
};
export default ReceiveManage;
