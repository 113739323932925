import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Switch,
  Space,
  Button,
} from "antd";
import Swal from 'sweetalert2'
import { createPaymentType, getPaymentTypeById, updatePaymentType } from "../../services/payment-type.service";
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 14 },
  },
};
const PaymentTypeManage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const onFinish = async (data) => {
    let timerInterval;
    var param = {
      paymentTypeId: paymentTypeId ?? "",
      paymentTypeName: data.paymentTypeName,
      detail: data.detail,
      isActive: isActive,
    }
    if (location?.state?.paymentTypeId) {
      const { data: updateData } = await updatePaymentType(param);
      if (updateData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            window.location.reload()
          }
        })
      } else {
        Swal.fire(updateData?.message, '', 'error')
      }
    } else {
      const { data: insertData } = await createPaymentType(param);
      if (insertData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            setTypeId(insertData?.id)
            navigate("/payment-type/manage", { state: { paymentTypeId: insertData?.id } });
            window.location.reload()
          }
        })
      } else {
        Swal.fire(insertData?.message, '', 'error')
      }
    }
  };
  const [paymentTypeId, setTypeId] = useState();
  const [isActive, setIsActive] = useState(true);
  const loadData = async (param) => {
    const { data } = await getPaymentTypeById(param)
    if (data) {
      setIsActive(data.isActive)
      form.setFieldsValue({
        paymentTypeId: data.paymentTypeId,
        paymentTypeName: data.paymentTypeName,
        detail: data.detail,
      });
    }
  }
  useEffect(() => {
    if (location?.state?.paymentTypeId) {
      setTypeId(location?.state?.paymentTypeId)
      loadData(location?.state?.paymentTypeId)
    } else {
      setIsActive(true)
    }
  }, []);
  return (
    <>
      <Card title="จัดการข้อมูลประเภทการชำระ" bordered={false}>
        <Form form={form} {...formItemLayout} onFinish={onFinish}>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชื่อประเภทการชำระ`}
                name="paymentTypeName"
                rules={[
                  { required: true, message: "กรุณากรอกชื่อประเภทการชำระ" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`ใช้งาน`} name="isActive">
                <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label={`รายละเอียด`} name="detail">
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="mt-3">
            <Col span={24}>
              <div style={{ textAlign: "center" }}>
                <Space size="middle">
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    กลับ
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#125a05" }}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};
export default PaymentTypeManage;
