import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'receive/'
const getReceivePaging = async (param) => {
    return await axios.get(
        API_URL +
        'GetPaging?currentPage=' +
        param?.currentPage +
        '&perPage=' +
        param?.perPage +
        '&sortColumn=' +
        param?.sortColumn +
        '&sortDirection=' +
        param?.sortDirection +
        '&search=' +
        param?.search +
        '&menuCode=' +
        param?.menuCode +
        '&supplierId=' +
        param?.supplierId +
        '&receiverId=' +
        param?.receiverId +
        '&dateStart=' +
        param?.dateStart +
        '&dateEnd=' +
        param?.dateEnd,
        { headers: authHeader() },
    )
}
const createReceive = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateReceive = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deleteReceive = async (param) => {
    return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getReceiveById = async (id) => {
    return await axios.get(API_URL + 'GetById?id=' + id, { headers: authHeader() })
}

export {
    getReceivePaging,
    createReceive,
    updateReceive,
    deleteReceive,
    getReceiveById,
}